@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    // ELEMENTS

    .list {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        max-height: 100%;
        width: auto;
        flex-wrap: wrap;
        border-bottom: 0.125rem solid $primary-color;
        padding: 0;
        margin: 0;
        justify-content: flex-start;
    }

    .tab {
        cursor: pointer;
        padding: 0.75rem 1.5rem;
        white-space: nowrap;
        box-sizing: border-box;
        display: block;
        border: none;
        text-transform: uppercase;
        font-family: $button-font-family;
        font-size: 0.875rem;
        font-weight: 500;
        transition:
            color 0.3s ease,
            background-color 0.3s ease;

        // STATES

        &:hover {
            background-color: $grey-50;
        }

        &:global {
            &.base--selected {
                background-color: $primary-color;
                color: $white;
                font-weight: 600;
            }
        }
    }

    .panel {
        padding-top: 1rem;
        display: none;

        &.active {
            display: block;
        }
    }

    .descriptor {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        &-icon {
            color: $primary-color;
            margin-right: 0.5rem;
            font-size: 1.2rem;
        }

        &-title {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    // MODIFIERS

    &.full-width {
        width: 100%;

        .list {
            display: flex;
            width: 100%;
        }
    }

    // MODIFIERS

    &.rounded {
        .tab {
            min-width: 12rem;
            text-align: center;
            @include border-radii(0.25rem, 0.25rem, 0, 0);
        }
    }

    &.text {
        .tab {
            position: relative;
            background-color: transparent;

            &:not(:first-child) {
                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0.75rem;
                    bottom: 0.75rem;
                    border-left: 1px solid $black;
                }
            }

            &:hover {
                background-color: transparent;
            }

            &:global {
                &.base--selected {
                    background-color: transparent;
                    color: $primary-color;
                    font-weight: 600;
                }
            }
        }
    }

    &.disabled {
        .tab {
            color: rgba($black, 0.5);

            &:not(:first-child) {
                &:before {
                    border-color: rgba($black, 0.5);
                }
            }

            &:global {
                &.base--selected {
                    color: rgba($black, 0.5);
                }
            }
        }
    }
}
