@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.wrapper {
}

.actions {
    text-align: right;
}

.table {
    width: 100%;
    border-collapse: collapse;

    &.fixed {
        table-layout: fixed;
    }

    &.variant-background-headers {
        .head {
            border: 0;
            background-color: #f5f5f5;
            font-weight: 800;
            font-size: rc(14);
            line-height: rc(20);

            .cell {
                padding: rc(10);
            }
        }

        .body {
            .cell {
                padding-top: rc(5);
                padding-bottom: rc(5);

                &:first-child {
                    padding-left: rc(10);
                }
            }
        }
    }
}

.head {
    border-bottom: 0.0625rem solid rgba($grey-600, 0.2);
    color: $grey-900;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    text-align: left;

    .sort {
        display: inline-flex;
        flex-direction: row;
        cursor: pointer;
    }

    .sort-icon {
        font-size: 0.75rem;
        margin-left: 0.5rem;
    }
}

.body {
    font-size: 0.875rem;
    line-height: 1.25rem;

    &:before {
        content: '';
        height: 0.25rem;
        display: block;
    }

    &:after {
        content: '';
        height: 0.25rem;
        display: block;
    }

    .row {
        &.is-selectable {
            cursor: pointer;
        }
    }

    &.has-hover {
        .row {
            &.is-selected,
            &:hover {
                margin-left: -0.5rem;
                padding-left: 0.5rem;
                background-color: $grey-50;
                @include border-radius(0.25rem);

                .cell {
                    position: relative;

                    &:first-child {
                        &:before {
                            position: absolute;
                            right: 100%;
                            top: 0;
                            bottom: 0;
                            content: '';
                            display: block;
                            width: 0.5rem;
                            background-color: $grey-50;
                            @include border-radii(0.25rem, 0, 0, 0.25rem);
                        }
                    }

                    &:last-child {
                        &:after {
                            position: absolute;
                            left: 100%;
                            top: 0;
                            bottom: 0;
                            content: '';
                            display: block;
                            width: 0.5rem;
                            background-color: $grey-50;
                            @include border-radii(0, 0.25rem, 0.25rem, 0);
                        }
                    }
                }

                :global {
                    .link {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.footer {
    border-top: 0.0625rem solid rgba($grey-600, 0.2);
}

.cell {
    box-sizing: border-box;
    min-height: 2rem;
    font-weight: inherit;
    line-height: inherit;
    padding: 0.25rem;
    vertical-align: middle;

    // ELEMENTS

    a {
        cursor: pointer;
    }

    // MODIFIERS

    &.size-small {
        width: 3rem;
    }

    &.size-medium {
        width: 12rem;
    }

    &.align-left {
        text-align: left;
    }

    &.align-right {
        text-align: right;
    }
}

.cell-content {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}
