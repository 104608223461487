@use 'sass:math';

// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/functions';
@import '../../../assets/scss/utils/mixins';
@import '../../../assets/scss/utils/breakpoints';

.root {
    // COLOR

    &.color-success {
        color: $success-color;
    }

    &.color-warning {
        color: $warning-color;
    }

    &.color-error {
        color: $error-color;
    }

    &.color-info {
        color: $grey-400;
    }

    &.color-primary {
        color: $primary-color;
    }

    &.color-secondary {
        color: $text-secondary;
    }
}
